body {
  color: #575757;
  background-color: #f2f2f2;
}

a {
  color: #6eab4d;
}
a.link-effect::before {
  background-color: #6eab4d;
}
a:hover {
  color: #41652d;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  color: #171717;
}

small,
.small {
  color: #646464;
}

.content-heading {
  border-bottom-color: #e8e8e8;
}

hr {
  border-top-color: #f2f2f2;
}

.text-primary {
  color: #6eab4d !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #4c7635 !important;
}

.text-primary-dark {
  color: #424242 !important;
}

a.text-primary-dark:hover, a.text-primary-dark:focus {
  color: #1c1c1c !important;
}

.text-primary-darker {
  color: #222 !important;
}

a.text-primary-darker:hover, a.text-primary-darker:focus {
  color: black !important;
}

.text-primary-light {
  color: #a0ca8a !important;
}

a.text-primary-light:hover, a.text-primary-light:focus {
  color: #76b255 !important;
}

.text-primary-lighter {
  color: #e4f0de !important;
}

a.text-primary-lighter:hover, a.text-primary-lighter:focus {
  color: #bad8a9 !important;
}

.text-body-bg {
  color: #f2f2f2 !important;
}

a.text-body-bg:hover, a.text-body-bg:focus {
  color: #cccccc !important;
}

.text-body-bg-light {
  color: #f7f7f7 !important;
}

a.text-body-bg-light:hover, a.text-body-bg-light:focus {
  color: #d1d1d1 !important;
}

.text-body-bg-dark {
  color: #e8e8e8 !important;
}

a.text-body-bg-dark:hover, a.text-body-bg-dark:focus {
  color: #c2c2c2 !important;
}

.text-body-color {
  color: #575757 !important;
}

a.text-body-color:hover, a.text-body-color:focus {
  color: #313131 !important;
}

.text-body-color-dark {
  color: #171717 !important;
}

a.text-body-color-dark:hover, a.text-body-color-dark:focus {
  color: black !important;
}

.text-body-color-light {
  color: #e8e8e8 !important;
}

a.text-body-color-light:hover, a.text-body-color-light:focus {
  color: #c2c2c2 !important;
}

.text-dual-primary-dark {
  color: #424242 !important;
}

a.text-dual-primary-dark:hover, a.text-dual-primary-dark:focus {
  color: #1c1c1c !important;
}

.page-header-inverse #page-header .text-dual-primary-dark,
.sidebar-inverse #sidebar .text-dual-primary-dark {
  color: #e8e8e8 !important;
}
.page-header-inverse #page-header a.text-dual-primary-dark:hover, .page-header-inverse #page-header a.text-dual-primary-dark:focus,
.sidebar-inverse #sidebar a.text-dual-primary-dark:hover,
.sidebar-inverse #sidebar a.text-dual-primary-dark:focus {
  color: #c2c2c2 !important;
}

.bg-primary {
  background-color: #6eab4d !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #57883d !important;
}

.bg-primary-op {
  background-color: rgba(110, 171, 77, 0.75) !important;
}

a.bg-primary-op:hover, a.bg-primary-op:focus,
button.bg-primary-op:hover,
button.bg-primary-op:focus {
  background-color: rgba(87, 136, 61, 0.75) !important;
}

.bg-primary-dark {
  background-color: #424242 !important;
}

a.bg-primary-dark:hover, a.bg-primary-dark:focus,
button.bg-primary-dark:hover,
button.bg-primary-dark:focus {
  background-color: #292929 !important;
}

.bg-primary-dark-op {
  background-color: rgba(66, 66, 66, 0.8) !important;
}

a.bg-primary-dark-op:hover, a.bg-primary-dark-op:focus,
button.bg-primary-dark-op:hover,
button.bg-primary-dark-op:focus {
  background-color: rgba(41, 41, 41, 0.8) !important;
}

.bg-primary-darker {
  background-color: #222 !important;
}

a.bg-primary-darker:hover, a.bg-primary-darker:focus,
button.bg-primary-darker:hover,
button.bg-primary-darker:focus {
  background-color: #090909 !important;
}

.bg-primary-light {
  background-color: #a0ca8a !important;
}

a.bg-primary-light:hover, a.bg-primary-light:focus,
button.bg-primary-light:hover,
button.bg-primary-light:focus {
  background-color: #84ba66 !important;
}

.bg-primary-lighter {
  background-color: #e4f0de !important;
}

a.bg-primary-lighter:hover, a.bg-primary-lighter:focus,
button.bg-primary-lighter:hover,
button.bg-primary-lighter:focus {
  background-color: #c8e0bb !important;
}

.bg-body {
  background-color: #f2f2f2 !important;
}

a.bg-body:hover, a.bg-body:focus,
button.bg-body:hover,
button.bg-body:focus {
  background-color: #d9d9d9 !important;
}

.bg-body-light {
  background-color: #f7f7f7 !important;
}

a.bg-body-light:hover, a.bg-body-light:focus,
button.bg-body-light:hover,
button.bg-body-light:focus {
  background-color: #dedede !important;
}

.bg-body-dark {
  background-color: #e8e8e8 !important;
}

a.bg-body-dark:hover, a.bg-body-dark:focus,
button.bg-body-dark:hover,
button.bg-body-dark:focus {
  background-color: #cecece !important;
}

.bg-gd-primary {
  background: #4c7635;
  background: linear-gradient(135deg, #4c7635 0%, #7bb65c 100%) !important;
}

.btn-link {
  color: #6eab4d;
}
.btn-link:hover, .btn-link:focus {
  color: #41652d;
}

.btn-primary {
  color: #fff;
  background-color: #6eab4d;
  border-color: #53813a;
}
.btn-primary:hover, .btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #53813a;
  border-color: #3c5e2a;
}
.btn-primary.disabled, .btn-primary:disabled {
  background-color: #6eab4d;
  border-color: #53813a;
}
.btn-primary:not([disabled]):not(.disabled):active {
  background-color: #6eab4d;
  border-color: #53813a;
}
.btn-primary:not([disabled]):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  background-color: #53813a;
  background-image: none;
  border-color: #3c5e2a;
}
.btn-primary.btn-noborder {
  border-color: #6eab4d;
}
.btn-primary.btn-noborder:hover, .btn-primary.btn-noborder:focus, .btn-primary.btn-noborder.focus {
  border-color: #53813a;
}
.btn-primary.btn-noborder.disabled, .btn-primary.btn-noborder:disabled, .btn-primary.btn-noborder:not([disabled]):not(.disabled):active, .btn-primary.btn-noborder:not([disabled]):not(.disabled).active {
  border-color: #6eab4d;
}
.show > .btn-primary.btn-noborder.dropdown-toggle {
  border-color: #53813a;
}

.btn-secondary {
  color: #212529;
  background-color: #f2f2f2;
  border-color: lightgray;
}
.btn-secondary:hover, .btn-secondary:focus, .btn-secondary.focus {
  color: #212529;
  background-color: lightgray;
  border-color: #bababa;
}
.btn-secondary.disabled, .btn-secondary:disabled {
  background-color: #f2f2f2;
  border-color: lightgray;
}
.btn-secondary:not([disabled]):not(.disabled):active {
  background-color: #f2f2f2;
  border-color: lightgray;
}
.btn-secondary:not([disabled]):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  background-color: lightgray;
  background-image: none;
  border-color: #bababa;
}
.btn-secondary.btn-noborder {
  border-color: #f2f2f2;
}
.btn-secondary.btn-noborder:hover, .btn-secondary.btn-noborder:focus, .btn-secondary.btn-noborder.focus {
  border-color: lightgray;
}
.btn-secondary.btn-noborder.disabled, .btn-secondary.btn-noborder:disabled, .btn-secondary.btn-noborder:not([disabled]):not(.disabled):active, .btn-secondary.btn-noborder:not([disabled]):not(.disabled).active {
  border-color: #f2f2f2;
}
.show > .btn-secondary.btn-noborder.dropdown-toggle {
  border-color: lightgray;
}

.btn-outline-primary {
  color: #6eab4d;
  background-color: transparent;
  background-image: none;
  border-color: #6eab4d;
}
.btn-outline-primary:hover, .btn-outline-primary:focus, .btn-outline-primary.focus {
  color: #fff;
  background-color: #6eab4d;
  border-color: #6eab4d;
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #6eab4d;
  background-color: transparent;
}
.btn-outline-primary:not([disabled]):not(.disabled):active {
  color: #6eab4d;
  background-color: transparent;
  border-color: #6eab4d;
}
.btn-outline-primary:not([disabled]):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #6eab4d;
  border-color: #6eab4d;
}
.btn-outline-primary.btn-noborder {
  border-color: transparent;
}
.btn-outline-primary.btn-noborder:not([disabled]):not(.disabled):active, .btn-outline-primary.btn-noborder:not([disabled]):not(.disabled).active {
  border-color: transparent;
}

.btn-outline-secondary {
  color: #828282;
  background-color: transparent;
  background-image: none;
  border-color: #828282;
}
.btn-outline-secondary:hover, .btn-outline-secondary:focus, .btn-outline-secondary.focus {
  color: #fff;
  background-color: #828282;
  border-color: #828282;
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #828282;
  background-color: transparent;
}
.btn-outline-secondary:not([disabled]):not(.disabled):active {
  color: #828282;
  background-color: transparent;
  border-color: #828282;
}
.btn-outline-secondary:not([disabled]):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #828282;
  border-color: #828282;
}
.btn-outline-secondary.btn-noborder {
  border-color: transparent;
}
.btn-outline-secondary.btn-noborder:not([disabled]):not(.disabled):active, .btn-outline-secondary.btn-noborder:not([disabled]):not(.disabled).active {
  border-color: transparent;
}

.btn-alt-primary {
  color: #355325;
  background-color: #e4f0de;
  border-color: #e4f0de;
}
.btn-alt-primary:hover, .btn-alt-primary:focus, .btn-alt-primary.focus {
  color: #355325;
  background-color: #c8e0bb;
  border-color: #c8e0bb;
  box-shadow: none;
}
.btn-alt-primary.disabled, .btn-alt-primary:disabled {
  background-color: #e4f0de;
  border-color: #e4f0de;
}
.btn-alt-primary:not([disabled]):not(.disabled):active {
  color: #355325;
  background-color: #e4f0de;
  border-color: #e4f0de;
}
.btn-alt-primary:not([disabled]):not(.disabled).active, .show > .btn-alt-primary.dropdown-toggle {
  color: #355325;
  background-color: #c8e0bb;
  border-color: #c8e0bb;
}

.btn-alt-secondary {
  color: #171717;
  background-color: #e8e8e8;
  border-color: #e8e8e8;
}
.btn-alt-secondary:hover, .btn-alt-secondary:focus, .btn-alt-secondary.focus {
  color: #171717;
  background-color: #cecece;
  border-color: #cecece;
  box-shadow: none;
}
.btn-alt-secondary.disabled, .btn-alt-secondary:disabled {
  background-color: #e8e8e8;
  border-color: #e8e8e8;
}
.btn-alt-secondary:not([disabled]):not(.disabled):active {
  color: #171717;
  background-color: #e8e8e8;
  border-color: #e8e8e8;
}
.btn-alt-secondary:not([disabled]):not(.disabled).active, .show > .btn-alt-secondary.dropdown-toggle {
  color: #171717;
  background-color: #cecece;
  border-color: #cecece;
}

.btn-dual-secondary {
  color: #1f1f1f;
  background-color: transparent;
  border-color: transparent;
}
.btn-dual-secondary:hover, .btn-dual-secondary:focus, .btn-dual-secondary.focus {
  color: #1f1f1f;
  background-color: #d9d9d9;
  border-color: #d9d9d9;
  box-shadow: none;
}
.btn-dual-secondary.disabled, .btn-dual-secondary:disabled {
  background-color: transparent;
  border-color: transparent;
}
.btn-dual-secondary:not([disabled]):not(.disabled):active {
  color: #1f1f1f;
  background-color: transparent;
  border-color: transparent;
}
.btn-dual-secondary:not([disabled]):not(.disabled).active, .show > .btn-dual-secondary.dropdown-toggle {
  color: #1f1f1f;
  background-color: #d9d9d9;
  border-color: #d9d9d9;
}

.page-header-inverse #page-header .btn-dual-secondary,
.sidebar-inverse #sidebar .btn-dual-secondary {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
}
.page-header-inverse #page-header .btn-dual-secondary:hover, .page-header-inverse #page-header .btn-dual-secondary:focus, .page-header-inverse #page-header .btn-dual-secondary.focus,
.sidebar-inverse #sidebar .btn-dual-secondary:hover,
.sidebar-inverse #sidebar .btn-dual-secondary:focus,
.sidebar-inverse #sidebar .btn-dual-secondary.focus {
  color: #fff;
  background-color: #222;
  border-color: #222;
  box-shadow: none;
}
.page-header-inverse #page-header .btn-dual-secondary.disabled, .page-header-inverse #page-header .btn-dual-secondary:disabled,
.sidebar-inverse #sidebar .btn-dual-secondary.disabled,
.sidebar-inverse #sidebar .btn-dual-secondary:disabled {
  background-color: transparent;
  border-color: transparent;
}
.page-header-inverse #page-header .btn-dual-secondary:not([disabled]):not(.disabled):active,
.sidebar-inverse #sidebar .btn-dual-secondary:not([disabled]):not(.disabled):active {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
}
.page-header-inverse #page-header .btn-dual-secondary:not([disabled]):not(.disabled).active, .show > .page-header-inverse #page-header .btn-dual-secondary.dropdown-toggle,
.sidebar-inverse #sidebar .btn-dual-secondary:not([disabled]):not(.disabled).active,
.show > .sidebar-inverse #sidebar .btn-dual-secondary.dropdown-toggle {
  color: #fff;
  background-color: #222;
  border-color: #222;
}

.alert-primary {
  color: #424242;
  background-color: #e4f0de;
  border-color: #c8e0bb;
}
.alert-primary hr {
  border-top-color: #bad8a9;
}
.alert-primary .alert-link {
  color: #292929;
}

.badge-primary {
  color: #fff;
  background-color: #6eab4d;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #57883d;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(110, 171, 77, 0.5);
}

.progress-bar {
  background-color: #6eab4d;
}

.nav-link {
  color: #575757;
}
.nav-link:hover, .nav-link:focus {
  color: #6eab4d;
}

.nav-tabs {
  border-bottom-color: #e8e8e8;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e8e8e8 #e8e8e8 #e8e8e8;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #575757;
  border-color: #e8e8e8 #e8e8e8 #fff;
}

.nav-tabs-block {
  background-color: #f7f7f7;
}
.nav-tabs-block .nav-link {
  border-color: transparent;
}
.nav-tabs-block .nav-link:hover, .nav-tabs-block .nav-link:focus {
  color: #6eab4d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs-block .nav-link.active,
.nav-tabs-block .nav-item.show .nav-link {
  color: #575757;
  background-color: #fff;
  border-color: transparent;
}

.nav-tabs-alt {
  border-bottom-color: #e8e8e8;
}
.nav-tabs-alt .nav-link {
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs-alt .nav-link:hover, .nav-tabs-alt .nav-link:focus {
  color: #6eab4d;
  background-color: transparent;
  border-color: transparent;
  box-shadow: inset 0 -2px #6eab4d;
}
.nav-tabs-alt .nav-link.active,
.nav-tabs-alt .nav-item.show .nav-link {
  background-color: transparent;
  border-color: transparent;
  box-shadow: inset 0 -2px #6eab4d;
}

.nav-pills .nav-link {
  color: #575757;
}
.nav-pills .nav-link:hover, .nav-pills .nav-link:focus {
  color: #6eab4d;
}
.nav-pills .nav-link.active,
.nav-pills .nav-item.show .nav-link {
  color: #fff;
  background-color: #6eab4d;
}

.page-item.active .page-link {
  background-color: #6eab4d;
  border-color: #6eab4d;
}

.page-link {
  color: #171717;
  background-color: #f2f2f2;
  border-color: #f2f2f2;
}
.page-link:hover, .page-link:focus {
  color: #171717;
  background-color: #e8e8e8;
  border-color: #e8e8e8;
}

.list-group-item-action {
  color: #575757;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  color: #575757;
  background-color: #f7f7f7;
}
.list-group-item-action:active {
  color: #575757;
  background-color: #e8e8e8;
}

.list-group-item {
  border-color: #e8e8e8;
}
.list-group-item.active {
  color: #fff;
  background-color: #6eab4d;
  border-color: #6eab4d;
}

.breadcrumb {
  background-color: #f2f2f2;
}

.breadcrumb-item.active {
  color: #171717;
}

.tooltip.bs-tooltip-top .arrow::before, .tooltip.bs-tooltip-auto[x-placement^=top] .arrow::before {
  border-top-color: #424242;
}
.tooltip.bs-tooltip-right .arrow::before, .tooltip.bs-tooltip-auto[x-placement^=right] .arrow::before {
  border-right-color: #424242;
}
.tooltip.bs-tooltip-bottom .arrow::before, .tooltip.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: #424242;
}
.tooltip.bs-tooltip-left .arrow::before, .tooltip.bs-tooltip-auto[x-placement^=left] .arrow::before {
  border-left-color: #424242;
}
.tooltip-inner {
  background-color: #424242;
}

.popover {
  border-color: #e8e8e8;
  border-radius: 2px;
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.05);
}
.popover.bs-popover-top .arrow::before, .popover.bs-popover-auto[x-placement^=top] .arrow::before {
  border-top-color: #e8e8e8;
}
.popover.bs-popover-top .arrow::after, .popover.bs-popover-auto[x-placement^=top] .arrow::after {
  border-top-color: #fff;
}
.popover.bs-popover-right .arrow::before, .popover.bs-popover-auto[x-placement^=right] .arrow::before {
  border-right-color: #e8e8e8;
}
.popover.bs-popover-right .arrow::after, .popover.bs-popover-auto[x-placement^=right] .arrow::after {
  border-right-color: #fff;
}
.popover.bs-popover-bottom .arrow::before, .popover.bs-popover-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: #e8e8e8;
}
.popover.bs-popover-bottom .arrow::after, .popover.bs-popover-auto[x-placement^=bottom] .arrow::after {
  border-bottom-color: #fff;
}
.popover.bs-popover-left .arrow::before, .popover.bs-popover-auto[x-placement^=left] .arrow::before {
  border-left-color: #e8e8e8;
}
.popover.bs-popover-left .arrow::after, .popover.bs-popover-auto[x-placement^=left] .arrow::after {
  border-left-color: #fff;
}
.modal-header {
  border-bottom-color: #e8e8e8;
}

.modal-footer {
  border-top-color: #e8e8e8;
}

.modal-backdrop {
  background-color: #080d06;
}

.dropdown-menu {
  border-color: #e8e8e8;
}

.dropdown-header {
  color: #171717;
}

.dropdown-item {
  color: #575757;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #575757;
  background-color: #f2f2f2;
}
.dropdown-item.active, .dropdown-item:active {
  color: #575757;
  background-color: #e8e8e8;
}

.dropdown-divider {
  border-top-color: #f2f2f2;
}

.table th,
.table td {
  border-top-color: #e8e8e8;
}
.table tbody + tbody {
  border-top-color: #e8e8e8;
}
.table .thead-dark th {
  background-color: #424242;
}
.table .thead-light th {
  background-color: #e8e8e8;
}

.table-bordered,
.table-bordered th,
.table-bordered td {
  border-color: #e8e8e8;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: #f2f2f2;
}

.table-hover .table-active:hover {
  background-color: #e5e5e5;
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: #e5e5e5;
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #e4f0de;
}

.table-hover .table-primary:hover {
  background-color: #d6e8cc;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #d6e8cc;
}

.table-dark {
  background-color: #424242;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #4f4f4f;
}

.table-responsive > .table.table-bordered thead th,
[class*=table-responsive-] > .table.table-bordered thead th {
  border-top: 1px solid #e8e8e8;
}

.form-control,
.input-group-text {
  color: #575757;
  background-color: #fff;
  border-color: #dbdbdb;
}

.input-group-text {
  background-color: #f7f7f7;
}

.form-control:focus {
  color: #171717;
  background-color: #fff;
  border-color: #a8a8a8;
}

select.form-control:focus::-ms-value {
  color: #575757;
  background-color: #fff;
}

.border-primary {
  border-color: #6eab4d !important;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #6eab4d;
  border-color: #6eab4d;
}
.custom-checkbox .custom-control-input:focus ~ .custom-control-label::before,
.custom-radio .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #f2f2f2, 0 0 0 3px #6eab4d;
}
.custom-checkbox .custom-control-input:active ~ .custom-control-label::before,
.custom-radio .custom-control-input:active ~ .custom-control-label::before {
  background-color: #8abd6e;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #6eab4d;
}

.custom-select {
  border-color: #dbdbdb;
}
.custom-select:focus {
  border-color: #a8a8a8;
}
.custom-select:focus::-ms-value {
  color: #575757;
  background-color: #fff;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #a8a8a8;
  box-shadow: none;
}
.custom-file-input:focus ~ .custom-file-label::after {
  border-color: #a8a8a8;
}

.custom-file-label {
  color: #575757;
  background-color: #fff;
  border-color: #dbdbdb;
}
.custom-file-label::after {
  color: #575757;
  background-color: #e8e8e8;
  border-left-color: #dbdbdb;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #9ccc65;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 4px 10px;
  margin-top: 0.1rem;
  font-size: 0.928571429rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(156, 204, 101, 0.9);
  border-radius: 0.25rem;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #9ccc65;
}
.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #9ccc65;
}
.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #9ccc65;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #9ccc65;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  background-color: #daecc5;
}
.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #b5d98b;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #9ccc65;
}
.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #9ccc65;
}

.is-valid .form-control {
  border-color: #9ccc65;
}
.is-valid .form-check-input ~ .form-check-label {
  color: #9ccc65;
}
.is-valid .custom-control-input ~ .custom-control-label {
  color: #9ccc65;
}
.is-valid .custom-control-input ~ .custom-control-label::before {
  background-color: rgba(156, 204, 101, 0.25);
}
.is-valid .valid-feedback,
.is-valid .valid-tooltip {
  display: block;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #ef5350;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 4px 10px;
  margin-top: 0.1rem;
  font-size: 0.928571429rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(239, 83, 80, 0.9);
  border-radius: 0.25rem;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #ef5350;
}
.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #ef5350;
}
.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #ef5350;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #ef5350;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  background-color: #fac6c5;
}
.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #f3817f;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #ef5350;
}
.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #ef5350;
}

.is-invalid .form-control {
  border-color: #ef5350;
}
.is-invalid .form-check-input ~ .form-check-label {
  color: #ef5350;
}
.is-invalid .custom-control-input ~ .custom-control-label {
  color: #ef5350;
}
.is-invalid .custom-control-input ~ .custom-control-label::before {
  background-color: rgba(239, 83, 80, 0.25);
}
.is-invalid .invalid-feedback,
.is-invalid .invalid-tooltip {
  display: block;
}

.form-material > .form-control {
  border-color: transparent;
  box-shadow: 0 1px 0 #dbdbdb;
}
.form-material > .form-control ~ .input-group-append {
  box-shadow: 0 1px 0 #dbdbdb;
}
.form-material > .form-control:focus {
  box-shadow: 0 2px 0 #a8a8a8;
}
.form-material > .form-control:focus + label {
  color: #575757;
}
.form-material > .form-control:focus ~ .input-group-append {
  box-shadow: 0 2px 0 #a8a8a8;
}
.form-material.form-material-primary > .form-control:focus {
  box-shadow: 0 2px #6eab4d;
}
.form-material.form-material-primary > .form-control:focus + label {
  color: #6eab4d;
}
.form-material.form-material-primary > .form-control:focus ~ .input-group-append {
  color: #6eab4d;
  box-shadow: 0 2px #6eab4d;
}

.is-valid .form-material > .form-control {
  box-shadow: 0 1px 0 #9ccc65;
}
.is-valid .form-material > .form-control ~ .input-group-append {
  color: #9ccc65;
  box-shadow: 0 1px 0 #9ccc65;
}
.is-valid .form-material > .form-control:focus {
  box-shadow: 0 2px #9ccc65;
}
.is-valid .form-material > .form-control:focus + label {
  color: #9ccc65;
}
.is-valid .form-material > .form-control:focus ~ .input-group-append {
  color: #9ccc65;
  box-shadow: 0 2px #9ccc65;
}
.is-valid .form-material ~ .invalid-feedback,
.is-valid .form-material ~ .invalid-tooltip {
  display: block;
}
.is-valid .form-material label {
  color: #9ccc65;
}

.is-invalid .form-material > .form-control {
  box-shadow: 0 1px 0 #ef5350;
}
.is-invalid .form-material > .form-control ~ .input-group-append {
  color: #ef5350;
  box-shadow: 0 1px 0 #ef5350;
}
.is-invalid .form-material > .form-control:focus {
  box-shadow: 0 2px #ef5350;
}
.is-invalid .form-material > .form-control:focus + label {
  color: #ef5350;
}
.is-invalid .form-material > .form-control:focus ~ .input-group-append {
  color: #ef5350;
  box-shadow: 0 2px #ef5350;
}
.is-invalid .form-material ~ .invalid-feedback,
.is-invalid .form-material ~ .invalid-tooltip {
  display: block;
}
.is-invalid .form-material label {
  color: #ef5350;
}

.css-radio:hover .css-control-input ~ .css-control-indicator {
  border-color: #e8e8e8;
}
.css-radio.css-control-primary .css-control-input:checked ~ .css-control-indicator::after {
  background-color: #6eab4d;
}

.css-checkbox:hover .css-control-input ~ .css-control-indicator {
  border-color: #e8e8e8;
}
.css-checkbox.css-control-primary .css-control-input:checked ~ .css-control-indicator {
  background-color: #6eab4d;
  border-color: #4c7635;
}

.css-switch.css-control-primary .css-control-input:checked ~ .css-control-indicator {
  background-color: #6eab4d;
}

.border {
  border-color: #e8e8e8 !important;
}
.border-t {
  border-top-color: #e8e8e8 !important;
}
.border-r {
  border-right-color: #e8e8e8 !important;
}
.border-b {
  border-bottom-color: #e8e8e8 !important;
}
.border-l {
  border-left-color: #e8e8e8 !important;
}

.border-light {
  border-color: #f7f7f7 !important;
}
.border-light-t {
  border-top-color: #f7f7f7 !important;
}
.border-light-r {
  border-right-color: #f7f7f7 !important;
}
.border-light-b {
  border-bottom-color: #f7f7f7 !important;
}
.border-light-l {
  border-left-color: #f7f7f7 !important;
}

#page-overlay {
  background: rgba(8, 13, 6, 0.4);
  background: linear-gradient(to right, rgba(8, 13, 6, 0), rgba(8, 13, 6, 0.6));
}
.sidebar-r #page-overlay {
  background: linear-gradient(to right, rgba(8, 13, 6, 0.6), rgba(8, 13, 6, 0));
}

#page-header {
  background-color: #fff;
}

.content-side-user {
  background-color: #f7f7f7;
}

#sidebar {
  background-color: #fff;
}

#side-overlay {
  background-color: #fff;
}

@media (min-width: 992px) {
  #page-container.page-header-modern #page-header {
    background-color: #f2f2f2;
  }
}
#page-container.page-header-inverse #page-header {
  color: lightgray;
  background-color: #424242;
}
#page-container.page-header-glass #page-header {
  background-color: transparent;
}
#page-container.page-header-glass.page-header-fixed.page-header-scroll #page-header {
  background-color: #fff;
}
#page-container.page-header-glass.page-header-fixed.page-header-scroll.page-header-inverse #page-header {
  background-color: #424242;
}
@media (min-width: 992px) {
  #page-container.page-header-modern #sidebar {
    box-shadow: 5px 0 10px #e8e8e8;
  }
  #page-container.page-header-modern.sidebar-r #sidebar {
    box-shadow: -5px 0 10px #e8e8e8;
  }
}
#page-container.sidebar-inverse #sidebar {
  color: #e8e8e8;
  background-color: #424242;
}
#page-container.sidebar-inverse #sidebar .content-side-user {
  background-color: #3a3a3a;
}

.block {
  box-shadow: 0 1px 1px #e8e8e8;
}
.block.block-transparent {
  box-shadow: none;
}

.block-header-default {
  background-color: #f7f7f7;
}

.block-title small {
  color: #646464;
}

.block.block-bordered {
  border-color: #e8e8e8;
}
.block.block-themed > .block-header {
  background-color: #6eab4d;
}

.block.block-mode-loading::after {
  color: #424242;
  box-shadow: 0 0 15px 15px rgba(66, 66, 66, 0.05);
}
.block.block-mode-loading.block-mode-hidden::after {
  box-shadow: 0 0 8px 8px rgba(66, 66, 66, 0.05);
}
.block.block-mode-loading.block-mode-loading-inverse::after {
  background-color: #424242;
}

a.block {
  color: #575757;
}
a.block:hover, a.block:focus {
  color: #575757;
}
a.block.block-link-pop:hover {
  box-shadow: 0 2px 30px #dedede;
}
a.block.block-link-pop:active {
  box-shadow: 0 2px 10px #efefef;
}
a.block.block-link-shadow:hover {
  box-shadow: 0 0 36px #dedede;
}
a.block.block-link-shadow:active {
  box-shadow: 0 0 10px #e8e8e8;
}

.block.block-fx-pop {
  box-shadow: 0 2px 30px #dedede;
}
.block.block-fx-shadow {
  box-shadow: 0 0 36px #dedede;
}

#page-loader {
  background-color: #6eab4d;
}

.nav-main a {
  color: rgba(87, 87, 87, 0.9);
}
.sidebar-inverse #sidebar .nav-main a {
  color: rgba(232, 232, 232, 0.75);
}

.nav-main a > i {
  color: rgba(66, 66, 66, 0.75);
}
.sidebar-inverse #sidebar .nav-main a > i {
  color: rgba(255, 255, 255, 0.3);
}

.nav-main a:hover > i, .nav-main a:focus > i, .nav-main a.active > i {
  color: #6eab4d;
}
.sidebar-inverse #sidebar .nav-main a:hover > i, .sidebar-inverse #sidebar .nav-main a:focus > i, .sidebar-inverse #sidebar .nav-main a.active > i {
  color: #6eab4d;
}

.nav-main ul a {
  color: #979797;
}
.nav-main li.open > a.nav-submenu > i {
  color: #6eab4d;
}
.sidebar-inverse #sidebar .nav-main li.open > a.nav-submenu > i {
  color: #6eab4d;
}

.nav-main li.open > ul {
  background-color: #f7f7f7;
}
.sidebar-inverse #sidebar .nav-main li.open > ul {
  background-color: #3a3a3a;
}

@media (min-width: 992px) {
  .nav-main-header a {
    color: #717171;
  }
  .nav-main-header ul a {
    color: #8f8f8f;
  }
  .nav-main-header a:hover,
.nav-main-header a:focus,
.nav-main-header a.active,
.nav-main-header li.open > a.nav-submenu,
.nav-main-header li:hover > a.nav-submenu {
    color: #000;
    background-color: #e8e8e8;
  }
  .nav-main-header ul,
.nav-main-header > li:hover > a.nav-submenu {
    background-color: #e0e0e0;
  }
  .page-header-inverse #page-header .nav-main-header a, .page-header-inverse.page-header-glass.page-header-scroll #page-header .nav-main-header a {
    color: #d1d1d1;
  }
  .page-header-inverse #page-header .nav-main-header ul a, .page-header-inverse.page-header-glass.page-header-scroll #page-header .nav-main-header ul a {
    color: #bababa;
  }
  .page-header-inverse #page-header .nav-main-header a:hover,
.page-header-inverse #page-header .nav-main-header a:focus,
.page-header-inverse #page-header .nav-main-header a.active,
.page-header-inverse #page-header .nav-main-header li.open > a.nav-submenu,
.page-header-inverse #page-header .nav-main-header li:hover > a.nav-submenu, .page-header-inverse.page-header-glass.page-header-scroll #page-header .nav-main-header a:hover,
.page-header-inverse.page-header-glass.page-header-scroll #page-header .nav-main-header a:focus,
.page-header-inverse.page-header-glass.page-header-scroll #page-header .nav-main-header a.active,
.page-header-inverse.page-header-glass.page-header-scroll #page-header .nav-main-header li.open > a.nav-submenu,
.page-header-inverse.page-header-glass.page-header-scroll #page-header .nav-main-header li:hover > a.nav-submenu {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.25);
  }
  .page-header-inverse #page-header .nav-main-header ul,
.page-header-inverse #page-header .nav-main-header > li:hover > a.nav-submenu, .page-header-inverse.page-header-glass.page-header-scroll #page-header .nav-main-header ul,
.page-header-inverse.page-header-glass.page-header-scroll #page-header .nav-main-header > li:hover > a.nav-submenu {
    background-color: #424242;
  }

  .page-header-inverse.page-header-glass #page-header .nav-main-header ul,
.page-header-inverse.page-header-glass #page-header .nav-main-header > li:hover > a.nav-submenu {
    background-color: #353535;
  }
}

.nav-users a {
  border-color: #f2f2f2;
}
.nav-users a:hover {
  background-color: #f7f7f7;
}

.list-timeline .list-timeline-time {
  background-color: #f7f7f7;
}

@media (min-width: 768px) {
  .list-timeline-modern::before {
    background-color: #f7f7f7;
  }
  .list-timeline-modern .list-timeline-time {
    background-color: transparent;
  }
}
.list-activity > li {
  border-bottom-color: #f2f2f2;
}

.ribbon-primary .ribbon-box {
  background-color: #6eab4d;
}
.ribbon-primary.ribbon-bookmark .ribbon-box::before {
  border-color: #6eab4d;
  border-left-color: transparent;
}
.ribbon-primary.ribbon-bookmark.ribbon-left .ribbon-box::before {
  border-color: #6eab4d;
  border-right-color: transparent;
}
.ribbon-primary.ribbon-modern .ribbon-box::before {
  border-color: #6eab4d;
  border-left-color: transparent;
  border-bottom-color: transparent;
}
.ribbon-primary.ribbon-modern.ribbon-bottom .ribbon-box::before {
  border-color: #6eab4d;
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-primary.ribbon-modern.ribbon-left .ribbon-box::before {
  border-color: #6eab4d;
  border-right-color: transparent;
  border-bottom-color: transparent;
}
.ribbon-primary.ribbon-modern.ribbon-left.ribbon-bottom .ribbon-box::before {
  border-color: #6eab4d;
  border-top-color: transparent;
  border-right-color: transparent;
}

.datepicker table tr td.active:hover,
.datepicker table tr td.active:hover:hover,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td.active:focus,
.datepicker table tr td.active:hover:focus,
.datepicker table tr td.active.disabled:focus,
.datepicker table tr td.active.disabled:hover:focus,
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active,
.open .dropdown-toggle.datepicker table tr td.active,
.open .dropdown-toggle.datepicker table tr td.active:hover,
.open .dropdown-toggle.datepicker table tr td.active.disabled,
.open .dropdown-toggle.datepicker table tr td.active.disabled:hover,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active:hover:hover,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active:focus,
.datepicker table tr td span.active:hover:focus,
.datepicker table tr td span.active.disabled:focus,
.datepicker table tr td span.active.disabled:hover:focus,
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active,
.open .dropdown-toggle.datepicker table tr td span.active,
.open .dropdown-toggle.datepicker table tr td span.active:hover,
.open .dropdown-toggle.datepicker table tr td span.active.disabled,
.open .dropdown-toggle.datepicker table tr td span.active.disabled:hover {
  background-color: #6eab4d;
  border-color: #6eab4d;
}

.cke_chrome,
.ck.ck-editor__main > .ck-editor__editable:not(.ck-focused),
.ck.ck-toolbar {
  border-color: #e8e8e8 !important;
}

.cke_top,
.ck.ck-toolbar {
  border-bottom-color: #e8e8e8 !important;
  background: #f7f7f7 !important;
}

.ck.ck-toolbar .ck.ck-toolbar__separator {
  background: #e8e8e8 !important;
}

.cke_bottom {
  border-top-color: #e8e8e8 !important;
  background: #f7f7f7 !important;
}

.dropzone {
  background-color: #f7f7f7;
  border-color: #dbdbdb;
}
.dropzone .dz-message {
  color: #575757;
}
.dropzone:hover {
  border-color: #6eab4d;
}
.dropzone:hover .dz-message {
  color: #6eab4d;
}

.fc-theme-bootstrap .fc-h-event {
  background-color: #6eab4d;
  border: #6eab4d;
}
.fc-theme-bootstrap .fc-col-header-cell {
  background-color: #f7f7f7;
}

.irs-line {
  background: #f2f2f2;
}
.irs-bar {
  background: #6eab4d;
}
.irs-bar-edge {
  background: #6eab4d;
}
.irs-from, .irs-to, .irs-single {
  background: #6eab4d;
}
.irs-grid-pol {
  background: #6eab4d;
}

.irs.irs--round .irs-min,
.irs.irs--round .irs-max,
.irs.irs--round .irs-line,
.irs.irs--round .irs-grid-pol {
  background: #f2f2f2;
}
.irs.irs--round .irs-handle {
  border-color: #6eab4d;
}
.irs.irs--round .irs-from::before,
.irs.irs--round .irs-to::before,
.irs.irs--round .irs-single::before {
  border-top-color: #6eab4d;
}
.irs.irs--round .irs-bar,
.irs.irs--round .irs-from,
.irs.irs--round .irs-to,
.irs.irs--round .irs-single {
  background: #6eab4d;
}

.autocomplete-suggestions {
  border-color: #e8e8e8;
}

.autocomplete-suggestion {
  color: #575757;
}
.autocomplete-suggestion b {
  color: #6eab4d;
}
.autocomplete-suggestion.selected {
  background-color: #f2f2f2;
}

div.tagsinput {
  border-color: #dbdbdb;
}
.form-material div.tagsinput {
  border-bottom: 1px solid #dbdbdb;
}
div.tagsinput span.tag {
  background-color: #6eab4d;
}

.draggable-placeholder {
  background-color: #efefef;
  border-color: #cecece;
}

.jvectormap-tip {
  background: #222;
}

.select2-container .select2-dropdown {
  border-color: #dbdbdb;
}

.select2-container--default .select2-selection--single {
  border-color: #dbdbdb;
}
.form-material .select2-container--default .select2-selection--single {
  border-bottom-color: #dbdbdb;
}
.select2-container--default .select2-selection--multiple {
  border-color: #dbdbdb;
}
.form-material .select2-container--default .select2-selection--multiple {
  border-bottom-color: #dbdbdb;
}
.select2-container--default.select2-container--focus .select2-selection--multiple, .select2-container--default.select2-container--focus .select2-selection--single {
  border-color: #a8a8a8;
}
.form-material .select2-container--default.select2-container--focus .select2-selection--multiple, .form-material .select2-container--default.select2-container--focus .select2-selection--single {
  border-bottom-color: #a8a8a8;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #6eab4d;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
  border-color: #dbdbdb;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #6eab4d;
}

.simplebar-scrollbar::before {
  background: rgba(9, 9, 9, 0.5);
}

.slick-slider .slick-prev::before,
.slick-slider .slick-next::before {
  color: #424242;
}

.dd-handle {
  color: #575757;
  background: #f7f7f7;
  border-color: #e8e8e8;
}
.dd-handle:hover {
  color: #171717;
}

.dd-empty,
.dd-placeholder {
  border-color: #222;
  background: #e4f0de;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  border-color: #6eab4d;
  background: #6eab4d;
}

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: #6eab4d;
}